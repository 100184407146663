@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'cabin';
        src: url('../fonts/cabin.woff2') format('woff2'),
        url('../fonts/cabin.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'opensans';
        src: url('../fonts/opensans.woff2') format('woff2'),
        url('../fonts/opensans.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
}



